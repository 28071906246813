/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getImage, GatsbyImage} from 'gatsby-plugin-image';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    h2: "h2",
    h3: "h3"
  }, _provideComponents(), props.components), {Box} = _components;
  if (!Box) _missingMdxReference("Box", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Karikatyyri prosessien ja vastuunjaon määrittelystä on jotain, jota tapahtuu ison yrityksen keskijohdossa, työkaluna on Excel, lopputuloksena PowerPoint ja koko asialla vähän jos ollenkaan tekemistä reaalimaailman kanssa. Eikä tämä ole ihan täysin tuulesta temmattu ajatus."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Prosessien kuvaus ja vastuunjaon määrittely voi kuitenkin olla aloittavan tai kasvuun kiihdyttävän yrityksen kriittisimpiä asioita. Kasvu edellyttää prosessien jatkuvaa kehittämistä, joten tarvitaan rakennetta niiden kuvaamiseen ja joku, joka asian omistaa. Jos jompi kumpi puuttuu tai on epäselvä, vääjämättä eteen tulevat kasvukivut voivat lamauttaa kasvun tai pahimmillaan koko yrityksen.")), "\n", React.createElement(_components.p, null, "Tässä artikkelissa kuvaan kolme erilaista rakennetta, joiden avulla yritys voi mallintaa omia prosessejaan. Vastuunjakomalliin syvennytään myöhemmässä artikkelissa."), "\n", React.createElement(_components.h2, null, "Rakenteita prosessien hahmottamiseen"), "\n", React.createElement(_components.p, null, "Prosesseja voi mallintaa usean eri rakenteen kautta. Rakenteissa on vivahde-eroja, joiden vuoksi joku niistä saattaa sopia yrityksen käyttöön luontevammin, kuin muut. Se, mikä rakenne auttaa parhaiten mallintamaan oman yrityksen prosesseja, riippuu ainakin yrityksen toimialasta, koosta ja olemassa olevista organisaatiorakenteista ja toimintatavoista, ja ehkä myös toiminnan maturiteetista."), "\n", React.createElement(_components.h3, null, "1. Yrityksen kolme päätoimintoa"), "\n", React.createElement(Box, {
    textAlign: "center"
  }, React.createElement(GatsbyImage, {
    image: getImage(props.data.mdx.frontmatter.images[0]),
    alt: ""
  })), "\n", React.createElement(_components.p, null, "Ensimmäinen malli jakaa yrityksen toiminnot hyvin perinteisellä tavalla myyntiin ja markkinointiin, tuotantoon ja hallintoon. Perinteissä ei kuitenkaan ole mitään vikaa. Tosiasiallisestihan jollain tapaa jokaisen yrityksen täytyy organisoida juuri nämä toiminnot, jotta yrityksen tuote tulee tuotettua, myytyä ja laskutettua. Erityisen luontevasti malli saattaa sopia esim. valmistavan teollisuuden yritykselle."), "\n", React.createElement(_components.h3, null, "2. Yrityksen kolme moottoria"), "\n", React.createElement(Box, {
    textAlign: "center"
  }, React.createElement(GatsbyImage, {
    image: getImage(props.data.mdx.frontmatter.images[1]),
    alt: ""
  })), "\n", React.createElement(_components.p, null, "Toisessa mallissa toiminta jaetaan kolmeen “moottoriin”. Ensimmäinen moottori on tutkimus ja tuotekehitys, joka kehittää yrityksen tulevaisuuden tuotteet ja palvelut. Toinen moottori - myynti ja markkinointia - löytää asiakkaat ja myy heille yrityksen tuotteet. Kolmantena moottorina toimii tuotanto ja asiakaspalvelu, jotka hoitaa tuotteen toimituksen ja tukee asiakkaita sen käytössä. Erona ensimmäiseen malliin se siis korostaa tutkimusta ja tuotekehitystä omana moottorinaan."), "\n", React.createElement(_components.p, null, "Tämä malli voi olla luonteva omaa tuotekehitystä, kuten ohjelmistokehitystä tekevälle yritykselle. Toki ohjelmistoyrityksessä tuotekehityksen ja tuotannon raja voi olla häilyvä ja tuotanto-otsikon alle menee vain asiakaspalvelu ja yleiset toiminnot. Mallin heikkoutena voidaan kuitenkin pitää juuri sitä, että yleiset, mutta täysin välttämättömät tukitoiminnot, kuten talous ja muu hallinto eivät ole näkyvissä. Tämä saattaa johtaa niiden jäämiseen liian vähälle huomiolle, joka taas puree varmasti ennemmin tai myöhemmin omaan nilkkaan."), "\n", React.createElement(_components.h3, null, "3. Arvon tuottamisen ketju"), "\n", React.createElement(Box, {
    textAlign: "center"
  }, React.createElement(GatsbyImage, {
    image: getImage(props.data.mdx.frontmatter.images[2]),
    alt: ""
  })), "\n", React.createElement(_components.p, null, "Kolmas malli lähtee arvon tuottamisesta. Se voi olla vaikeaselkoisempi, kuin kaksi ensimmäistä, mutta voi toisaalta tarjota aivan uudenlaista ajattelua ja näkökulmaa yrityksen tuottamaan ", React.createElement(_components.strong, null, "asiakasarvoon"), ", kun kaikki fokus on nimenomaan arvon tuottamisessa. Vaikka konkreettisia prosesseja lopulta mallinnettaisiinkin perinteisimmillä malleilla, yrityksen johdon olisi hyvä pohtia myös toimintoja nimenomaan arvon tuottamisen näkökulmasta."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Arvon luonti (Value Creation):"), " Miten yritys luo uutta arvoa tuotekehityksen, tuotannon tai tehokkaan hankinnan kautta. Perinteisessä kaupassa tämä on se osa, jossa ostetaan markalla (jotta voidaan sitten myydä kahdella)."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Arvon haltuunotto (Value Capture):"), " Miten yritys saa itselleen tuotetusta arvonlisästä osansa myynnin, markkinoinnin ja hinnoittelun myötä (markalla ostettu tavara myydään kahdella)"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Arvon toimittaminen (Value Delivery):"), " Miten yritys toimittaa arvon asiakkaalle laadukkaasti ja ajallaan."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Tukitoiminot:"), " Kaiki yleiset toiminnot, joilla mahdollistetaan ja tuetaan arvontuotantoketjua."), "\n", React.createElement(_components.p, null, "P.S. Ajatusharjoituksena kannattaa muuten pohtia tai paperin kulmaan piirrellä miten kukin malleista sopii yhteen strategiakartan kanssa."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
